import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Icon, Statistic, Dropdown, Button } from 'semantic-ui-react'
import { Redirect } from '@reach/router'
import { DateInput } from 'semantic-ui-calendar-react'
import circle from './citrus_circle.svg'
import RecipeCardHeader from './RecipeCardHeader'
import { fetchSku } from './api'

const breakpoints = [500, 768, 992, 1200]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const print = '@media print'

const MainMargin = styled('div')({
  margin: 20,
  flexWrap: 'wrap',
  display: 'flex',
  position: 'relative',
  flex: '1 0',
  [print]: {
    margin: '0'
  },
  [mq[0]]: {
    margin: '50px 30px'
  }
})

const ShoppingListWrapper = styled('div')({
  [print]: {
    margin: ' 0 15px 15px 15px',
    minWidth: 'calc( 100% )',
    maxWidth: 'calc( 100% )',
    background: 'none',
    minHeight: 'calc(100vh - 15px)',
    '@page': {
      margin: '0',
      minHeight: 'calc(100vh - 30px)'
    }
  }
})

const RecipeParent = styled('div')({
  padding: '15px',
  minWidth: 'calc( 100% )',
  maxWidth: 'calc( 100% )',
  background: 'linear-gradient(180deg, #f5f5f5, #FFF, #FFF, #FFF, #FFF, #FFF)',
  position: 'relative',
  [print]: {
    margin: ' 0 15px 15px 15px',
    minWidth: 'calc( 100% )',
    maxWidth: 'calc( 100% )',
    background: 'none',
    minHeight: 'calc(100vh - 15px)',
    '@page': {
      margin: '0'
    }
  },
  [mq[0]]: {
    minWidth: 'calc( 100% )',
    maxWidth: 'calc( 100% )'
  },
  [mq[1]]: {
    minWidth: 'calc( 50% - 1px )',
    maxWidth: 'calc( 50% - 1px )',
    background: 'none'
  },
  [mq[2]]: {
    minWidth: 'calc( 50% - 1px )',
    maxWidth: 'calc( 50% - 1px )',
    background: 'none'
  },
  [mq[3]]: {
    minWidth: 'calc( 33.33333333333% - 1px )',
    maxWidth: 'calc( 33.33333333333% - 1px )',
    background: 'none'
  }
})

const ShoppingListParent = styled(RecipeParent)({
  [print]: {
    margin: '0px',
    height: 'unset',
    maxHeight: 'unset',
    minHeight: 'unset'
  },
  minWidth: '100%',
  maxWidth: '100%',
  background: 'none'
})

const PageBreak = styled('span')({
  pageBreakBefore: 'always',
  position: 'absolute',
  bottom: '1px',
  height: 1,
  [print]: {
    width: '100%'
  }
})

const HorizontalDiv = styled('div')({
  margin: '15px 0',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  width: '100%'
})

const MenuSelection = styled(HorizontalDiv)({
  margin: '-15px 0 15px 0',
  background: '#EFEFEF',
  alignItems: 'center',
  padding: '5px 10px',
  [print]: {
    display: 'none'
  }
})

const InfoBlock = styled('div')({
  margin: '15px 0',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
})

const ServingWidth = styled('div')({
  margin: '0 15px',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(33.333333333% - 30px)',
  '&:first-child': {
    margin: '0 15px 0 0'
  },
  '&:last-child': {
    margin: '0 0 0 15px'
  }
})

const Header = styled('h1')({
  color: '#8bc34a',
  fontFamily: 'faith_glory',
  margin: 0,
  fontSize: '2.2rem',
  fontWeight: 400
})

const HeaderSmall = styled('h1')({
  color: '#8bc34a',
  fontFamily: 'faith_glory',
  margin: 0,
  fontSize: '1.5rem',
  fontWeight: 400
})

const SansHeader = styled('h1')({
  color: '#8bc34a',
  fontFamily: 'Oswald, Helvetica, Sans',
  margin: 0,
  fontSize: '1rem',
  fontWeight: 800,
  textAlign: 'center',
  [mq[0]]: {
    fontSize: '1rem'
  }
})

const SansHeaderGray = styled('h2')({
  color: '#cacaca',
  fontFamily: 'Oswald, Helvetica, Sans',
  margin: 0,
  fontSize: '.8rem',
  fontWeight: 800,
  textAlign: 'center',
  [mq[0]]: {
    fontSize: '1.1rem'
  }
})

const SansHeaderGrayInstructions = styled(SansHeaderGray)({
  width: 'unset',
  fontSize: '.8rem',
  margin: '0 5px 0 0',
  textAlign: 'left',
  width: '50%',
  [mq[0]]: {
    width: '75%'
  }
})

const SansHeaderServing = styled(SansHeader)({
  fontSize: '.7rem',
  [mq[0]]: {
    fontSize: '1rem'
  }
})

const SansHeaderServingSub = styled(SansHeader)({
  fontSize: '1rem',
  color: 'black',
  [mq[0]]: {
    fontSize: '1.2rem'
  }
})

const CookTimeTypeContainer = styled(HorizontalDiv)({
  margin: 0,
  justifyContent: 'center',
  flexDirection: 'column',
  width: '50%',
  [mq[0]]: {
    width: '50%'
  }
})

const CookInstructionsDiv = styled(HorizontalDiv)({
  flexDirection: 'column',
  [mq[0]]: {
    flexDirection: 'row'
  }
})
const CookTimeLabel = styled(HeaderSmall)({
  color: '#000',
  textAlign: 'center',
  [mq[0]]: {
    textAlign: 'left'
  }
})

const CookTime = styled(HorizontalDiv)({
  margin: 0,
  marginTop: 5,
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%'
})

const CookTimeHeader = styled(SansHeader)({
  fontSize: '1.1rem',
  marginTop: '3px',
  width: '100%'
})

const SubHeader = styled('span')(props => {
  return {
    color: '#000',
    fontFamily: 'Oswald, Helvetica, Sans',
    fontSize: props.big ? '.9rem' : '.7rem',
    fontWeight: 300,
    marginBottom: '10px',
    [mq[0]]: {
      fontSize: props.big ? '1.3rem' : '.9rem'
    }
  }
})

const SubHeaderInstructions = styled(SubHeader)({
  fontSize: '.7rem',
  marginBottom: '5px',
  lineHeight: '1.2rem',
  [mq[0]]: {
    fontSize: '1rem'
  }
})

const TextColumn = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '.6rem',
  [mq[0]]: {
    fontSize: '.8rem'
  }
})

const NotFound = styled('div')({
  padding: 20,
  display: 'flex',
  position: 'relative',
  flex: '1 0',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 136px)'
})

const MenuHome = styled(NotFound)({
  flexDirection: 'column'
})

const Logo = styled('img')({
  maxWidth: 50,
  position: 'absolute',
  right: 10,
  top: 10,
  display: 'block'
})

export const getSku = (date, Code, sku_format = 'YY-MMDD-LC', defaultSku = 'Error') => {
  return date && Code
    ? sku_format
        .replace(/YY-MMDD/, date.format ? date.format('YY-MMDD') : formatSkuDate(date))
        .replace(/LC/, Code)
    : defaultSku
}

function formatSkuDate(mm_dd_yyyy) {
  let date = new Date(mm_dd_yyyy)
  let dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  })
  let [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date)
  return `${year}-${month}${day}`
}

function parseDateFromSku(sku) {
  let dateString = String(sku).substr(0, 7)
  let match = /^(\d\d)-(\d\d)(\d\d)/.exec(dateString)
  return new Date(Number(match[1]), Number(match[2]) - 1, Number(match[3]))
}

export default function ClassPdf(props) {
  const { menu, recipeHeader } = props
  // console.log("ClassPdf", recipeHeader)
  const [date, setDate] = useState('')
  const [location, setLocation] = useState('')
  const [newSku, setNewSku] = useState('19-0212-SM')
  const handleChange = (event, { value }) => {
    setDate(value)
  }
  useEffect(() => {
    const tmp = getSku(date, location)
    // console.log("tmp", tmp, date, location);
    setNewSku(tmp)
  }, [date, location])

  useEffect(() => {
    'YY-MMDD-LC'
    if (props.sku.includes('-')) {
      let skuDate = parseDateFromSku(props.sku)
      // console.log("tmp", tmp.format(), String(props.sku).substr(0, 7));
      setDate(
        skuDate
          .toLocaleDateString(0, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
          .replace(/\//g, '-')
      )
      let tmpLoc = props.sku.substr(8, 10)
      tmpLoc = tmpLoc === 'AS' ? props.sku.slice(-2) : tmpLoc
      setLocation(tmpLoc)
    }
  }, [props.sku])

  useEffect(() => {}, [recipeHeader])

  const options = props.locations.map(l => ({
    key: l.Code,
    text: l.name,
    value: l.Code
  }))

  const hasMenu = Array.isArray(menu) && menu.length > 0
  return (
    <div>
      <MenuSelection>
        <Dropdown
          clearable={true}
          placeholder="Location"
          options={options}
          selection
          search
          scrolling
          value={location}
          style={{ height: '37px', minWidth: '14.25rem' }}
          onChange={(e, t) => {
            // console.log(e, e.target, e.target.value, t);
            setLocation(t.value)
          }}
        />
        <DateInput
          name="date"
          dateFormat="MM-DD-YYYY"
          placeholder="Date"
          value={date}
          iconPosition="left"
          onChange={handleChange}
          pickerStyle={{ background: 'transparent', border: 'none' }}
        />
        <Link to={`/menu/${newSku}`} style={{ marginLeft: 10 }}>
          {/* <Button color="olive" compact onClick={trackClick}> */}
          <Button color="olive" compact>
            Load
          </Button>
        </Link>
      </MenuSelection>
      {props.sku === 'home' ? (
        <MenuHome>
          <div style={{ maxWidth: '75vw', width: '100%', textAlign: 'center' }}>
            <PickClassInfo />
            <div
              style={{
                alignSelf: 'center',
                textAlign: 'center',
                marginTop: '20px'
              }}
            >
              If you wish to just see all current menu options{' '}
              <Link to={`/menu/all`}> click here</Link>
              <br />
              <div
                style={{
                  fontSize: '.8rem',
                  fontStyle: 'italic',
                  marginTop: '20px'
                }}
              >
                We're always improving our recipes, for the most accurate instructions please select
                your class above.
              </div>
            </div>
          </div>
        </MenuHome>
      ) : (
        <Fragment>
          {hasMenu ? (
            <MainMargin>
              {props.sku !== 'all' && (
                <ShoppingListWrapper>
                  <Header style={{ width: '100%' }}>A few tips for storage and cooking</Header>
                  <hr style={{ margin: '3px 0' }} />
                  <HorizontalDiv>
                    <RecipeCardHeader html={recipeHeader.html} />
                    <PageBreak />
                  </HorizontalDiv>
                </ShoppingListWrapper>
              )}
              {props.sku !== 'all' && (
                <ShoppingListWrapper>
                  <Header style={{ width: '100%' }}>Shopping List</Header>
                  <hr style={{ margin: '3px 0' }} />
                  <HorizontalDiv>
                    {menu
                      .filter(r => r.id)
                      .map(recipe => (
                        <ShoppingList key={'shopping_' + recipe.id} recipe={recipe} />
                      ))}
                    <PageBreak />
                  </HorizontalDiv>
                </ShoppingListWrapper>
              )}
              <div style={{ width: '100%', height: '1px' }} />
              <hr style={{ margin: '3px 0' }} />
              {menu
                .filter(r => r.id)
                .map(recipe => (
                  <Recipe key={recipe.id} recipe={recipe} />
                ))}
            </MainMargin>
          ) : (
            <NotFound>
              <div style={{ maxWidth: '75vw' }}>
                No Class found for this Date and Location. Please check your selection and try
                again.
              </div>
            </NotFound>
          )}
        </Fragment>
      )}
    </div>
  )
}

export function ShoppingList(props) {
  const { name, grocery1, grocery2, grocery3, grocery4, grocery5, grocery6 } = props.recipe
    ? props.recipe
    : {}
  return [
    <ShoppingListParent key={1}>
      <HeaderSmall>{name}</HeaderSmall>
      <hr style={{ margin: '3px 0' }} />
      <SubHeaderInstructions>
        {[
          ...(grocery1 ? [grocery1] : []),
          ...(grocery2 ? [grocery2] : []),
          ...(grocery3 ? [grocery3] : []),
          ...(grocery4 ? [grocery4] : []),
          ...(grocery5 ? [grocery5] : []),
          ...(grocery6 ? [grocery6] : [])
        ].join(', ')}
      </SubHeaderInstructions>
    </ShoppingListParent>
  ]
}

export function Recipe(props) {
  const {
    name,
    desc,
    heart = true,
    calConsc = true,
    diabetes = true,
    serveSuggestions,
    addiSeas,
    calories,
    tfat,
    carbs,
    fiber,
    protein,
    sugar,
    satFat,
    sodium = 0,
    cook_time_sl_sm,
    cook_time_sl_rg,
    cook_time_pr_sm,
    cook_time_pr_rg,
    cook_inst_sl_sm,
    cook_inst_sl_rg,
    cook_inst_pr_sm,
    cook_inst_pr_rg,
    grocery1,
    grocery2,
    grocery3,
    grocery4,
    grocery5,
    grocery6,
    serve_amount_sm,
    serve_amount_rg,
    serve_size,
    nutritionNotes
  } = props.recipe ? props.recipe : {}
  return (
    <RecipeParent key={1}>
      <Logo src="/Citrus-Pear-Logo.png" />
      <Header>{name}</Header>
      <SubHeader big={desc.length < 62}>{desc}</SubHeader>
      <hr style={{ margin: '3px 0' }} />
      <SubHeaderInstructions>
        {[
          ...(grocery1 ? [grocery1] : []),
          ...(grocery2 ? [grocery2] : []),
          ...(grocery3 ? [grocery3] : []),
          ...(grocery4 ? [grocery4] : []),
          ...(grocery5 ? [grocery5] : []),
          ...(grocery6 ? [grocery6] : [])
        ].join(', ')}
      </SubHeaderInstructions>
      <hr style={{ margin: '3px 0' }} />
      <CookInstructionsDiv style={{ margin: 0 }}>
        <CookInstructionsDiv style={{ margin: 0, flexWrap: 'nowrap' }}>
          <CookTimeTypeContainer>
            <SansHeader style={{ width: '100%' }}>SLOW COOKER COOK TIME</SansHeader>
            <HorizontalDiv style={{ margin: 0, flexWrap: 'nowrap' }}>
              <CookTime>
                <SansHeaderGray>SMALL FAMILY</SansHeaderGray>
                <CookTimeHeader>{cook_time_sl_sm}</CookTimeHeader>
              </CookTime>
              <CookTime>
                <SansHeaderGray>REGULAR FAMILY</SansHeaderGray>
                <CookTimeHeader>{cook_time_sl_rg}</CookTimeHeader>
              </CookTime>
            </HorizontalDiv>
          </CookTimeTypeContainer>
          <CookTimeTypeContainer>
            <SansHeader style={{ width: '100%' }}>PRESSURE COOKER COOK TIME</SansHeader>
            <HorizontalDiv style={{ margin: 0, flexWrap: 'nowrap' }}>
              <CookTime>
                <SansHeaderGray>SMALL FAMILY</SansHeaderGray>
                <CookTimeHeader>{cook_time_pr_sm}</CookTimeHeader>
              </CookTime>
              <CookTime>
                <SansHeaderGray>REGULAR FAMILY</SansHeaderGray>
                <CookTimeHeader>{cook_time_pr_rg}</CookTimeHeader>
              </CookTime>
            </HorizontalDiv>
          </CookTimeTypeContainer>
        </CookInstructionsDiv>
      </CookInstructionsDiv>
      <hr />
      <InfoBlock>
        <HeaderSmall>Slow Cooker Instructions</HeaderSmall>
        <TextColumn>
          <SansHeaderGrayInstructions>SMALL FAMILY</SansHeaderGrayInstructions>
          <SubHeaderInstructions>{cook_inst_sl_sm}</SubHeaderInstructions>
        </TextColumn>
        <TextColumn>
          <SansHeaderGrayInstructions>REGULAR FAMILY</SansHeaderGrayInstructions>
          <SubHeaderInstructions>{cook_inst_sl_rg}</SubHeaderInstructions>
        </TextColumn>
      </InfoBlock>
      <InfoBlock>
        <HeaderSmall>Pressure Cooker Instructions</HeaderSmall>
        <TextColumn>
          <SansHeaderGrayInstructions>SMALL FAMILY</SansHeaderGrayInstructions>
          <SubHeaderInstructions>{cook_inst_pr_sm}</SubHeaderInstructions>
        </TextColumn>
        <TextColumn>
          <SansHeaderGrayInstructions>REGULAR FAMILY</SansHeaderGrayInstructions>
          <SubHeaderInstructions>{cook_inst_pr_rg}</SubHeaderInstructions>
        </TextColumn>
      </InfoBlock>
      <InfoBlock>
        <HeaderSmall style={{ fontSize: '1rem' }}>Additional Seasonings</HeaderSmall>
        <SubHeaderInstructions style={{ marginBottom: 'auto' }}>
          Additional seasonings to be added 30-60 min before serving for stronger flavors:
          <br />
          {addiSeas}
        </SubHeaderInstructions>
      </InfoBlock>
      <InfoBlock>
        <HeaderSmall>Additional Instructions</HeaderSmall>
        <SubHeaderInstructions>
          {serveSuggestions ? serveSuggestions : 'none'}
        </SubHeaderInstructions>
      </InfoBlock>
      <hr />
      <HorizontalDiv style={{ margin: 0 }}>
        <ServingWidth>
          <SansHeaderServing>SERVING SIZE</SansHeaderServing>
          <SansHeaderServingSub>{serve_size}</SansHeaderServingSub>
        </ServingWidth>
        <ServingWidth>
          <SansHeaderServing>SMALL FAMILY</SansHeaderServing>
          <SansHeaderServingSub>{serve_amount_sm}</SansHeaderServingSub>
        </ServingWidth>
        <ServingWidth>
          <SansHeaderServing>REGULAR FAMILY</SansHeaderServing>
          <SansHeaderServingSub>{serve_amount_rg}</SansHeaderServingSub>
        </ServingWidth>
      </HorizontalDiv>
      <hr />
      {nutritionNotes && (
        <HorizontalDiv style={{ justifyContent: 'center', margin: '5px 0 -15px 0' }}>
          <SubHeaderInstructions style={{ fontStyle: 'italic' }}>
            {nutritionNotes ? nutritionNotes : 'None.'}
          </SubHeaderInstructions>
        </HorizontalDiv>
      )}
      <HorizontalDiv style={{ margin: '15px 0px 5px 0px', justifyContent: 'center' }}>
        <Circle top={`${calories}`} bottom={'CALORIES'} />
        <Circle top={`${tfat}g`} bottom={'TOT. FAT'} />
        <Circle top={`${carbs}g`} bottom={'CARBS'} />
        <Circle top={`${fiber}g`} bottom={'FIBER'} />
        <Circle top={`${protein}g`} bottom={'PROTEIN'} />
        <Circle top={`${sugar}g`} bottom={'SUGARS'} />
        <Circle top={`${satFat}g`} bottom={'SAT. FAT'} />
        <Circle top={`${sodium}mg`} bottom={'SODIUM'} />
      </HorizontalDiv>
      <HorizontalDiv style={{ justifyContent: 'center', margin: '5px 0 0 0' }}>
        <SubHeaderInstructions style={{ fontStyle: 'italic' }}>
          {heart && 'Heart Healthy. '}
          {calConsc && 'Calorie Healthy. '}
          {diabetes && 'Diabetes Friendly. '}
        </SubHeaderInstructions>
      </HorizontalDiv>
      {/* <PageBreak key={2} /> */}
    </RecipeParent>
  )
}

const ImgCircle = styled('img')({
  width: 'calc(12.5vw - 20px)',
  height: 'calc(12.5vw - 20px)',
  [mq[1]]: {
    width: 'calc(60px - 25px)',
    height: 'calc(60px - 25px)'
  },

  [print]: {
    width: 'calc(8vw)',
    height: 'calc(8vw)'
  }
})

const CircleContainer = styled('div')({
  paddingRight: '10px',
  [print]: {
    paddingRight: '2vw'
  },
  display: 'flex',
  position: 'relative'
})

const CircleTextContainer = styled('div')({
  width: 'calc(12.5vw - 20px)',
  height: 'calc(12.5vw - 20px)',
  fontSize: '.8rem',
  [mq[1]]: {
    width: 'calc(60px - 25px)',
    height: 'calc(60px - 25px)',
    fontSize: '1rem'
  },
  [print]: {
    fontSize: '1.6em',
    width: 'calc(8vw)',
    height: 'calc(8vw)'
  },
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: '1em',
  fontFamily: '"Oswald", Helvetica, "Arial Narrow", Arial, sans-serif',
  fontStretch: 'condensed'
})

const CircleText = styled('span')({
  color: 'black',
  fontWeight: '800',
  fontSize: '.65em'
})

export function Circle(props) {
  const { top, bottom } = props
  return (
    <CircleContainer>
      <ImgCircle src={circle} alt="" />
      <CircleTextContainer>
        <CircleText style={{ fontSize: top.toLowerCase().includes('mg') ? '.7em' : '1.1em' }}>
          {top}
        </CircleText>
        <CircleText>{bottom}</CircleText>
      </CircleTextContainer>
    </CircleContainer>
  )
}

export function OrderRedirect(props) {
  const [sku, setSku] = useState('Loading...')

  useEffect(() => {
    async function translateOrder() {
      let tempSku = await fetchSku(props.match.params.order_id)
      setSku(tempSku)
    }
    // console.log("useEffect was Called in OrderRedirect!");
    translateOrder()
  }, [])

  return (
    <div>
      {sku === 'Loading...' ? (
        <div>Loading SKU information...</div>
      ) : sku === 'None' ? (
        <div>SKU couldn't be found. Please contact Citrus Pear with this error.</div>
      ) : (
        <Redirect to={`/menu/${sku}`} />
      )}
    </div>
  )
}

const PickClassInfo = () => (
  <Statistic.Group
    widths="three"
    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
  >
    <Statistic>
      <Statistic.Value text>
        <Icon name="map pin" /> Pick <br />
        Location
      </Statistic.Value>
    </Statistic>

    <Statistic>
      <Statistic.Value text>
        <i aria-hidden="true" className="calendar icon" /> Pick <br />
        CLASS Date
      </Statistic.Value>
    </Statistic>

    <Statistic>
      <Statistic.Value text>
        Click To
        <br />
        LOAD
      </Statistic.Value>
      <Statistic.Label>your class menu</Statistic.Label>
    </Statistic>
  </Statistic.Group>
)
