
import React, {  useState, useEffect } from 'react';
import { convertToRaw } from 'draft-js';
import {
  Editor,
  createEditorState,
} from 'medium-draft';
import mediumDraftImporter from 'medium-draft/lib/importer';

function RecipeTextEditor(props)
{
  const { html= ""} = props;
  const [editorState, setEditorState] = useState(
    createEditorState()
  );

  useEffect(() =>
  {
    const editorState = createEditorState(convertToRaw(mediumDraftImporter(html)));
    setEditorState(editorState);
    console.log(html)
  }, [html]);

  return (
    <div style={{
      width: "100%"
    }}>
      <Editor
        editorEnabled={false}
        onChange={()=>{}}
        disableToolbar={true}
        editorState={editorState}
        readonly={true}
        />
    </div>
  );
}

export default RecipeTextEditor;