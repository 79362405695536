import React, { Component } from 'react'
import { Link } from '@reach/router'
import { Loader, Container } from 'semantic-ui-react'
import { fetchMenu, fetchLocations, fetchRecipeHeader } from '../../components/ClassPdf/api'

import ClassPdf from '../../components/ClassPdf'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locations: [],
      recipeHeader: '',
      menu: [],
      loading: false
    }
  }

  async componentDidMount() {
    this.readMenu()
    // gaPageview("/menu");
    console.log('read menu...')
    const locations = await fetchLocations()
    console.log('fetchLocations...')
    const recipeHeader = await fetchRecipeHeader()
    console.log('fetchRecipeHeader...')
    console.log(recipeHeader)
    this.setState({ locations, recipeHeader })
  }

  componentDidUpdate(prevProps) {
    const { sku } = this.props

    if (sku !== prevProps.sku) {
      this.readMenu(1)
    }
  }

  async readMenu() {
    const { sku } = this.props
    console.log({ sku })
    this.setState({ loading: true })
    const menu = await fetchMenu(sku)
    this.setState({ menu, loading: false })
  }

  render() {
    const { sku } = this.props
    const { locations, recipeHeader, menu, loading } = this.state
    // console.log(this.props.state);
    if (loading === true) {
      return (
        <div>
          <Loader active />
        </div>
      )
    }
    // return <div>{JSON.stringify(menu)}</div>
    if (
      menu === undefined ||
      (Array.isArray(menu) &&
        menu[0] === "class data wasn't found, cannot calulate." &&
        sku !== 'home')
    ) {
      const [yr, dt, loc] = sku.split('-')
      const assembledSku = `${yr}-${dt}-AS-${loc}`
      return (
        <Container>
          <p>
            No menu found. If Citrus Pear prepared your meals for pickup, and you didn't attend a
            class in person, try loading our assembled meal for the{' '}
            <Link to={`/menu/${assembledSku}`}>same day.</Link>
          </p>
        </Container>
      )
    }

    return <ClassPdf menu={menu} sku={sku} locations={locations} recipeHeader={recipeHeader} />
  }
}

export default Menu
