
import { api_url } from '../../enum'
var orderBy = require('lodash/orderBy');

export const fetchMenu = (sku) =>
{
    return new Promise((res, rej) => {
        if (!sku) return rej(new Error("sku was not defined."));
        const url = `${api_url}/pdf/${String(sku).toUpperCase()}`;
        console.log(url);
        return fetch(url)
          .then(response => response.json())
          .then(json => res(orderBy(json, ["name"], ["asc"])))
          .catch(rej);
    })
};

export const fetchLocations = (e) =>
{
    return new Promise((res, rej) => {
        const url = `${api_url}/locations/`;
        console.log(url);
        return fetch(url)
          .then(response => response.json())
          .then(json => res(orderBy(json, ["name"], ["asc"])))
          .catch(rej);
    })
};


export const fetchRecipeHeader = (e) =>
{
    return new Promise((res, rej) => {
        const url = `${api_url}/recipe_header/`;
        console.log(url);
        return fetch(url)
          .then(response => response.json())
          .then(json => res(json))
          .catch(rej);
    })
};


export const fetchSku = (order_id) =>
{
    return new Promise((res, rej) => {
        if (!order_id) return rej(new Error("Order_id was not defined."));
        const url = `${api_url}/translateorder2sku/${order_id}`;
        console.log(url);
        return fetch(url)
          .then(response => response.json())
          .then(json => res(json))
          .catch(rej);
    })
};